<template>
  <div class="main-box">
    <div class="test-submit">
      <custom-frame title="提交测评结果" :testType="5"></custom-frame>
      <div class="main">
        <div class="text">
          <div class="up">已完成所有测评</div>
          <div class="down">提交测评查看您的测评分析</div>
        </div>
        <div class="submit-button" @click="submitAnswerList">提交测评</div>
      </div>
    </div>
  </div>
</template>

<script>
  import API from '@/api/config'
  // import {UpgradePathTest} from '@/api/test/test'
  import CustomFrame from '@/views/test/custom-components/CustomFrame'
  import { getStore } from "@/utils/utils";
  export default {
    components:{
      CustomFrame
    },
    name: "test-submit",
    data(){
      return{}
    },
    mounted(){},
    methods:{

      submitAnswerList(){
        let temp = getStore('LoginData')
        if(!temp) {
          this.$message.error('无法获取到登录信息，请重新登录！')
          return
        }
        API.Career.UpgradePathTest({
          UserId: JSON.parse(temp).UserId,
          AnswerList: this.$ls.get('test-enterPath-answerList')
        }).then(res => {
          this.$router.push({
            path: '/test-result',
            query: {
              testType: '5'
            }
          })
        })
      }
    }
  }
</script>

<style scoped lang="less">
  .main-box{
    width: 100%;
    background: linear-gradient(to bottom, #F4D25E, #F95940);
  }
  .test-submit{
    max-width: 750px;
    margin: 0 auto;
    border: 1px solid #eeefff;
    position: relative;
    .main{
      box-sizing: border-box;
      width: 100%;
      padding: 40px;
      position: absolute;
      top: 150px;
      left: 0;
      .text{
        line-height: 1.5;
        text-align: center;
        font-weight: 700;
        color: #515357;
        font-family: PingFang SC;
      }
      .submit-button{
        width: 180px;
        padding: 15px;
        margin: 150px auto 0;
        text-align: center;
        background: linear-gradient(to right, #f9904a, #F9573E);
        border-radius: 30px;
        color: #ffffff;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
      }
    }
  }

</style>
